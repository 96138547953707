import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Client } from 'src/app/shared/model/client';
import { ClientService } from 'src/app/shared/service/client.service';
import { Lob } from 'src/app/shared/model/lob';
import { LobService } from 'src/app/shared/service/lob.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientMenuComponent implements OnInit {
  clients: Client[];
  lobs: Lob[];
  id: string;
  client: Client;
  clientSelectionHidden = false;

  constructor(
    private clientService: ClientService,
    private lobService: LobService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.url.subscribe(() => {
      if (this.route.firstChild) {
        this.route.firstChild.params.subscribe((params) => {
          this.id = params['clientId'];
          if (this.id) {
            this.clientService.getClient(this.id).subscribe(data => {
              this.client = {
                id: data.payload.id,
                ...data.payload.data() as Client
              } as Client;
            });
            this.lobService.getClientLobs(this.id).subscribe(data => {
              this.lobs = data.map(e => {
                return {
                  id: e.payload.doc.id,
                  ...e.payload.doc.data() as Lob,
                  getTeams: this.lobService.getTeams(e.payload.doc.id)
                } as Lob;
              })
            });
          }
          else {
            this.route.firstChild.firstChild.url.subscribe(url => {
              if (url[0].path != "create") {
                this.navigateToClient();
              }
              else {
                this.client = {} as Client;
              }
            });
          }
        });
      }
      else {
        this.navigateToClient();
      }
    });
    this.clientService.getClients().subscribe(data => {
      this.clients = data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Client
        } as Client;
      });
    });

  }

  navigateToClient() {
    this.clientService.getClients().subscribe(data => {
      this.router.navigate(['app', 'client', data[0].payload.doc.id, 'edit']);
    });
  }

  lobsForClient(id: string) {
    return this.lobs.filter(x => x.client.id == id);
  }

  teamsForLob(id: string){
    console.log("call");
    return this.lobService.getTeams(id);
  }
}
