import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private titleMsg: BehaviorSubject<string> = new BehaviorSubject<string>("Devometer");
  title = this.titleMsg.asObservable();
  private touchAllowed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  touch = this.touchAllowed.asObservable();

  constructor(private router: Router) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.setTitle("Devometer");
        }
      });
  }

  setTitle(title: string) {
    this.titleMsg.next(title);
  }

  setTouchAllowed(allow: boolean){
    this.touchAllowed.next(allow);
  }
}
