
  <mat-toolbar color="primary">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <img src="assets/icon-small.png" width="40" class="logo" />
    <span>DEVOMETER</span>
    <span fxFlex></span>
    <ngx-auth-firebaseui-avatar (onSignOut)="signOut()"></ngx-auth-firebaseui-avatar>
  </mat-toolbar>
  <mat-sidenav-container fxFlex="100" class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [class.handset]="isHandset$ | async" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false" fxLayout="column">
      <client-menu></client-menu>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" [class.touchDisabled]="!touchAllowed">
      <div class="content" fxFlex="100">
        <ng-content></ng-content>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
