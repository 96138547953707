<form [formGroup]="form" novalidate (ngSubmit)="onSubmit(form.value)" *ngIf="form">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Client</mat-card-title>
    </mat-card-header>
    <mat-card-content class="create-form">
      <mat-form-field>
        <input matInput placeholder="Name" class="form-control" formControlName="name">
      </mat-form-field>
      <ng-container *ngFor="let validation of validation_messages.name">
        <mat-error *ngIf="form.get('name').hasError(validation.type) && (form.get('name').dirty || form.get('name').touched)">{{validation}}</mat-error>
      </ng-container>
      <mat-form-field>
        <input matInput placeholder="Location" class="form-control" formControlName="location">
      </mat-form-field>
      <ng-container *ngFor="let validation of validation_messages.location">
        <mat-error *ngIf="form.get('location').hasError(validation.type) && (form.get('location').dirty || form.get('location').touched)">{{validation}}</mat-error>
      </ng-container>
      <mat-form-field>
        <input matInput placeholder="Industry" class="form-control" formControlName="industry">
      </mat-form-field>
      <ng-container *ngFor="let validation of validation_messages.industry">
        <mat-error *ngIf="form.get('industry').hasError(validation.type) && (form.get('industry').dirty || form.get('industry').touched)">{{validation}}</mat-error>
      </ng-container>
      <mat-form-field>
        <input matInput placeholder="Size" class="form-control" formControlName="size">
      </mat-form-field>
      <ng-container *ngFor="let validation of validation_messages.size">
        <mat-error *ngIf="form.get('size').hasError(validation.type) && (form.get('size').dirty || form.get('size').touched)">{{validation}}</mat-error>
      </ng-container>
      <mat-form-field>
        <input matInput placeholder="Web" class="form-control" formControlName="web">
      </mat-form-field>
      <div class="image-upload" *ngIf="client">
        <h4>Logo</h4>
        <div class="image" *ngIf="logo">
          <img [src]="logo" width="50" height="50" />
        </div>
        <div *ngIf="!logo && client.logo" class="image">
          <img *ngIf="'ClientLogo/thumbnails/'+client.id+'_50x50' | fireStorageImage | async as photoUrl" [src]="photoUrl" width="50" height="50" />
        </div>
        <div *ngIf="!logo && !client.logo" class="no-image">{{ form.get('name').value.substr(0,2) | uppercase }}</div>
        <div class="buttons">
          <input type="text" formControlName="logo" #fileName />
          <input type="file" (change)="showPreview($event)" #fileInput />
          <button mat-raised-button (click)="uploadClick()">
            <mat-icon>file_upload</mat-icon> Upload
          </button>
        </div>
      </div>
      <ng-container *ngFor="let validation of validation_messages.web">
        <mat-error *ngIf="form.get('web').hasError(validation.type) && (form.get('web').dirty || form.get('web').touched)">{{validation}}</mat-error>
      </ng-container>
    </mat-card-content>
    <mat-card-actions>
      <div *ngIf="!id" class="button-bottom">
        <button mat-raised-button class="submit-button" color="primary" type="submit" [disabled]="!form.valid">Create</button>
      </div>
      <div *ngIf="id" class="button-bottom">
        <button mat-raised-button class="submit-button" color="primary" type="submit" [disabled]="!form.valid || !form.dirty">Update</button>
        <button mat-raised-button class="submit-button" color="warn" (click)="openDialog(deleteDialog)">Delete</button>
      </div>
    </mat-card-actions>
  </mat-card>
</form>

<mat-card class="example-card" *ngIf="client">
  <mat-card-header>
    <mat-card-title>Ownership</mat-card-title>
  </mat-card-header>
  <mat-card-content class="ownership-form">
    <div *ngFor="let owner of client.owners; let i = index ; trackBy:trackByFn" class="owner-line">
      <mat-form-field>
        <input matInput placeholder="Email" [name]="'owner'+owner" class="form-control" [value]="getOwner(owner).email" readonly>
      </mat-form-field>
      <mat-icon>delete</mat-icon>
    </div>
    <div *ngFor="let owner of createOwners; let i = index" class="owner-line">
      <mat-form-field>
        <input matInput placeholder="Email" [name]="'owner'+i" class="form-control" [(ngModel)]="owner.email">
      </mat-form-field>
      <mat-icon (click)="createOwner(owner)">check</mat-icon>
    </div>
    <button mat-button class="lob-add" (click)="createOwners.push({email:''})">
      <mat-icon>add_circle_outline</mat-icon> <span>Add Owner</span>
    </button>
  </mat-card-content>
</mat-card>

<ng-template #deleteDialog>
  <h2 matDialogTitle>Delete</h2>
  <mat-dialog-content>
    <p>Are you sure you want to delete this Client?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Cancel</button>
    <button mat-button matDialogClose color="warn" (click)="deleteClient()">Delete</button>
  </mat-dialog-actions>
</ng-template>

<mat-card class="example-card" *ngIf="lobs">
  <mat-card-header>
    <mat-card-title>Lines Of Business</mat-card-title>
  </mat-card-header>
  <mat-card-content class="create-form">
    <div *ngFor="let lob of lobs" class="lob-line">
      <mat-form-field>
        <input matInput placeholder="Name" [name]="'lob'+lob.id" class="form-control" [(ngModel)]="lob.name">
      </mat-form-field>
      <mat-icon (click)="lobToDelete = lob.id; openDialog(deleteLobDialog)">delete</mat-icon>
    </div>
    <div *ngFor="let lob of createLobs; let i = index" class="lob-line">
      <mat-form-field>
        <input matInput placeholder="Name" [name]="'lob'+i" class="form-control" [(ngModel)]="lob.name">
      </mat-form-field>
      <mat-icon (click)="createLob(lob)">check</mat-icon>
    </div>
    <button mat-button class="lob-add" (click)="createLobs.push({name:''})">
      <mat-icon>add_circle_outline</mat-icon> <span>Create New</span>
    </button>
  </mat-card-content>
</mat-card>

<ng-template #deleteLobDialog>
  <h2 matDialogTitle>Delete</h2>
  <mat-dialog-content>
    <p>Are you sure you want to delete this Line Of Business?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Cancel</button>
    <button mat-button matDialogClose color="warn" (click)="deleteLob(lobToDelete)">Delete</button>
  </mat-dialog-actions>
</ng-template>
