import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private firestore: AngularFirestore
  ) { }

  getUserByEmail(email:string) {
    return this.firestore.collection('users', ref => ref.where('email', '==', email)).snapshotChanges();
  }

  getUser(uid: string) {
    return this.firestore.collection('users', ref => ref.where('uid', '==', uid)).snapshotChanges();
  }
}
