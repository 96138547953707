import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';

import { Client } from 'src/app/shared/model/client';
import { ClientService } from 'src/app/shared/service/client.service';
import { User } from 'src/app/shared/model/user';
import { UserService } from 'src/app/shared/service/user.service';
import { Lob } from 'src/app/shared/model/lob';
import { LobService } from 'src/app/shared/service/lob.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TitleService } from 'src/app/shared/service/title.service';

import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {
  client: Client;
  lobs: Lob[];
  createLobs: any[] = [];
  createOwners: any[] = [];
  create = true;
  validation_messages: any = {};
  id: string;
  init = true;
  form: FormGroup;
  formLobs: FormGroup;
  lobToDelete: string;
  logo: any;
  logoFile: any;
  logoURL: string;
  owners: User[] = [];
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileName') fileName: ElementRef;

  constructor(
    private clientService: ClientService,
    private lobService: LobService,
    private fb: FormBuilder,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private storage: AngularFireStorage,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.id = params['clientId'];
      if (this.id) {
        this.clientService.getClient(this.id).subscribe(data => {
          this.client = {
            id: data.payload.id,
            ...data.payload.data() as Client
          } as Client;
          this.client.owners.forEach(owner => {
            this.userService.getUser(owner).subscribe(data => {
              this.owners.push(data[0].payload.doc.data() as User);
            });
          });
          this.createForm();
          if (this.init) {
            this.titleService.setTitle("Edit Client: " + this.client.name);
            this.init = false;
          }
        });

        this.lobService.getClientLobs(this.id).subscribe(data => {
          this.lobs = data.map(e => {
            return {
              id: e.payload.doc.id,
              ...e.payload.doc.data() as Lob
            } as Lob;
          })
        });
      }
      else {
        this.createForm();
        this.titleService.setTitle("Create Client");
      }
    })
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.client && this.client.name ? this.client.name : ''],
      location: [this.client && this.client.location ? this.client.location : ''],
      industry: [this.client && this.client.industry ? this.client.industry : ''],
      size: [this.client && this.client.size ? this.client.size : ''],
      web: [this.client && this.client.web ? this.client.web : ''],
      logo: [this.client && this.client.logo ? this.client.logo : ''],
    });
  }

  onSubmit(value: Client) {
    if (this.form.get('logo').dirty) {
      const file = this.logoFile;
      const filePath = `ClientLogo/${this.client.id}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(`ClientLogo/${this.client.id}`, file);
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(url => {
              if (url) {
                this.logoURL = url;
              }
            });
          })
        )
        .subscribe();
    }
    if (this.id) {
      this.clientService.updateClient({ id: this.id, ...value })
    }
    else {
      this.clientService.createClient(value)
        .then(doc => {
          this.router.navigateByUrl("app/client/" + doc.id + "/edit")
        })
        .catch(err => {
          console.log("error: ", err);
        })
    }
  }

  openDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  deleteClient() {
    this.clientService.deleteClient(this.id);
    this.router.navigateByUrl("/");
  }

  deleteLob(id: string) {
    this.lobService.deleteLob(id);
  }

  createLob(lob: Lob) {
    this.lobService.createLob(lob, this.client.id).then(
      () => {
        this.createLobs.splice(this.createLobs.indexOf(lob), 1);
      }
    );
  }

  createOwner(owner: string) {
    this.clientService.addOwner(owner, this.id).then(
      () => {
        this.createOwners.splice(this.createOwners.indexOf(owner), 1);
      }
    )
  }

  showPreview(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.logoFile = event.target.files[0];
      const [file] = event.target.files;
      this.form.get('logo').setValue(file.name);
      this.form.get('logo').markAsDirty();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.logo = reader.result as string;
      };
    }
  }

  uploadClick() {
    this.fileInput.nativeElement.click();
  }

  trackByFn(index: any) {
    return index;
  }

  getOwner(uid: string) {
    return this.owners ? this.owners.find(user => user.uid == uid) ? this.owners.find(user => user.uid == uid) : {email:''} : {email:''};
  }
}
