import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Pipe({name: 'fireStorageImage'})

export class FireStorageImagePipe implements PipeTransform {

    constructor(public storage: AngularFireStorage){}

    transform(path: any): any {
        let photo = this.storage.ref(path).getDownloadURL();
        return photo;
    }
}
