import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Lane } from '../model/lane';
import { Sorting } from '../model/sorting';
import { Team } from '../model/team';
import { Card } from '../model/card';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  getLanes(){
    return this.firestore.collection('lanes', ref => ref.orderBy('order')).snapshotChanges().pipe(
      map(changes => changes.map(({ payload: { doc } }) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...data as Lane } as Lane;
      }))
    );
  }

  getSortings(teamId: string, lobId: string){
    return this.firestore.collection('lobs/' + lobId + '/teams/' + teamId + '/sortings').snapshotChanges().pipe(
      map(changes => changes.map(({ payload: { doc } }) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...data as Sorting } as Sorting;
      }))
    );
  }

  createSorting(sorting: Sorting, teamId: string, lobId: string) {
    return this.firestore.collection('lobs/'+lobId+'/teams/'+teamId+'/sortings').add({
      ...sorting,
      created: firebase.default.firestore.FieldValue.serverTimestamp()
    });
  }

  updateSorting(sorting: Sorting, teamId: string, lobId: string) {
    const sortingId = sorting.id;
    delete sorting.id;
    this.firestore.doc('lobs/' + lobId + '/teams/' + teamId + '/sortings/' + sortingId).update(sorting);
  }

  getCards(teamId: string, lobId: string){
    return this.firestore.collection('lobs/' + lobId + '/teams/' + teamId + '/cards').snapshotChanges().pipe(
      map(changes => changes.map(({ payload: { doc } }) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...data as Card } as Card;
      }))
    );
  }

  createCard(card: Card, teamId: string, lobId: string) {
    return this.firestore.collection('lobs/'+lobId+'/teams/'+teamId+'/cards').add({
      ...card,
      lane: this.firestore.collection('lanes').doc(card.lane.id).ref,
      created: firebase.default.firestore.FieldValue.serverTimestamp()
    });
  }

  updateCard(card: Card, teamId: string, lobId: string) {
    const cardId = card.id;
    delete card.id;
    return this.firestore.doc('lobs/' + lobId + '/teams/' + teamId + '/cards/' + cardId).update(card);
  }

  deleteCard(cardId: string, teamId: string, lobId: string) {
    this.firestore.doc('lobs/' + lobId + '/teams/' + teamId + '/cards/' + cardId).delete();
  }
}
