<div [class.opened-card]="activeCard" class="outbox">
  <div fxFill>
    <div *ngIf="lanes" fxLayout="row" fxFlex="100" cdkDropListGroup class="board">
      <mat-card class="lane" fxFlex="100" *ngFor="let lane of lanes">
        <h3>{{lane.name}}</h3>
        <div cdkDropList [cdkDropListData]="lane.cards" class="list" (cdkDropListDropped)="drop($event)" [id]="lane.id">
          <div class="box" *ngFor="let item of lane.cards" (cdkDragStarted)="startDrag()" (cdkDragEnded)="endDrag()"
            cdkDrag>
            {{item.name}}
            <button mat-icon-button class="card-edit" (click)="setActive(item)">
              <mat-icon>mode</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="lane.createCard" class="card-line">
          <mat-form-field>
            <input matInput placeholder="Name" [name]="'create-card-'+lane.id" class="form-control"
              [(ngModel)]="lane.createCardName">
          </mat-form-field>
          <mat-icon (click)="createCard(lane)">check</mat-icon>
        </div>
        <button *ngIf="!lane.createCard" mat-button class="card-add" (click)="lane.createCard = true">
          <mat-icon>add_circle_outline</mat-icon> <span>Create New</span>
        </button>
      </mat-card>
    </div>
  </div>
</div>
<div *ngIf="activeCard" class="card-detail">
  <button mat-icon-button class="card-close" (click)="activeCard = null">
    <mat-icon>close</mat-icon>
  </button>
  <form [formGroup]="form" novalidate (ngSubmit)="onSubmit(form.value)" *ngIf="form">
    <mat-form-field class="title">
      <input matInput placeholder="Title" class="form-control" formControlName="name">
    </mat-form-field>
    <ng-container *ngFor="let validation of validation_messages.name">
      <mat-error
        *ngIf="form.get('name').hasError(validation.type) && (form.get('name').dirty || form.get('name').touched)">
        {{validation}}</mat-error>
    </ng-container>
    <mat-form-field>
      <input matInput placeholder="What is the problem?" class="form-control" formControlName="problem">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="How do you measure the problem?" class="form-control" formControlName="problem_metric">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="What is your hypothesis?" class="form-control" formControlName="hypothesis">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="How will you measure success?" class="form-control" formControlName="success_metric">
    </mat-form-field>
    <mat-form-field class="size">
      <mat-label>Complexity</mat-label>
      <mat-select formControlName="complexity">
        <mat-option *ngFor="let size of sizeOptions" [value]="size">
          {{size}}
        </mat-option>
      </mat-select>
    </mat-form-field>    
    <mat-form-field class="size">
      <mat-label>Value</mat-label>
      <mat-select formControlName="value">
        <mat-option *ngFor="let size of sizeOptions" [value]="size">
          {{size}}
        </mat-option>
      </mat-select>
    </mat-form-field> 
    <mat-form-field>
      <input matInput placeholder="Outcome summary" class="form-control" formControlName="outcome">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Notes" class="form-control" formControlName="notes">
    </mat-form-field>   
    <mat-form-field>
      <input matInput placeholder="Reporter" class="form-control" formControlName="reporter">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Assignee" class="form-control" formControlName="assignee">
    </mat-form-field>
    <div class="action-buttons">
      <button mat-raised-button class="submit-button" color="primary" type="submit" [disabled]="!form.valid || !form.dirty">Save</button>
      <button mat-raised-button class="submit-button" color="warn" (click)="openDialog(deleteDialog)">Delete</button>
    </div>
  </form>
</div>

<ng-template #deleteDialog>
  <h2 matDialogTitle>Delete</h2>
  <mat-dialog-content>
    <p>Are you sure you want to delete this Card?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose color="primary">Cancel</button>
    <button mat-button matDialogClose color="warn" (click)="deleteActiveCard()">Delete</button>
  </mat-dialog-actions>
</ng-template>
