<mat-card class="example-card" *ngIf="teams">
    <mat-card-header>
      <mat-card-title>Teams</mat-card-title>
    </mat-card-header>
    <mat-card-content class="create-form">
      <div *ngFor="let team of teams" class="team-line">
        <mat-form-field>
          <input matInput placeholder="Name" [name]="'team'+team.id" class="form-control" [(ngModel)]="team.name">
        </mat-form-field>
        <mat-icon (click)="teamToDelete = team.id; openDialog(deleteTeamDialog)">delete</mat-icon>
      </div>
      <div *ngFor="let team of createTeams; let i = index" class="team-line">
        <mat-form-field>
          <input matInput placeholder="Name" [name]="'team'+i" class="form-control" [(ngModel)]="team.name">
        </mat-form-field>
        <mat-icon (click)="createTeam(team)">check</mat-icon>
      </div>
      <button mat-button class="team-add" (click)="createTeams.push({name:''})">
        <mat-icon>add_circle_outline</mat-icon> <span>Create New</span>
      </button>
    </mat-card-content>
</mat-card>

<ng-template #deleteTeamDialog>
    <h2 matDialogTitle>Delete</h2>
    <mat-dialog-content>
      <p>Are you sure you want to delete this Team?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose color="primary">Cancel</button>
      <button mat-button matDialogClose color="warn" (click)="deleteTeam(teamToDelete)">Delete</button>
    </mat-dialog-actions>
  </ng-template>
  