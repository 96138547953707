import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';

import { Team } from 'src/app/shared/model/team';
import { Card } from 'src/app/shared/model/card';
import { Lane } from 'src/app/shared/model/lane';
import { Sorting } from 'src/app/shared/model/sorting';
import { UserService } from 'src/app/shared/service/user.service';
import { Lob } from 'src/app/shared/model/lob';
import { LobService } from 'src/app/shared/service/lob.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TitleService } from 'src/app/shared/service/title.service';

import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { CardService } from 'src/app/shared/service/card.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {
  lobId: string;
  teamId: string;
  lanes: Lane[];
  formTeams: FormGroup;
  createTeams: any[] = [];
  teamToDelete: string;
  init = true;
  teams: Team[];
  lob: Lob;
  cards: any[];

  getCards: any;
  getLanes: any;
  getSortings: any;
  activeCard: Card;
  validation_messages: any = {};
  form: FormGroup;
  sizeOptions = ["","XS","S","M","L","XL"];
  
  constructor(
    private lobService: LobService,
    private fb: FormBuilder,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private cardService: CardService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lobId = params['lobId'];
      this.teamId = params['teamId'];

      if (this.lobId && this.teamId) {
        this.getCards = this.cardService.getCards(this.teamId, this.lobId);
        this.getLanes = this.cardService.getLanes();
        this.getSortings = this.cardService.getSortings(this.teamId, this.lobId);

        this.getCards.subscribe(data => {
          this.cards = data;
          this.getSortings.subscribe(sortings => {
            this.getLanes.subscribe(lanes => {
              this.lanes = lanes.map(lane => ({
                ...lane,
                cards: this.cards.filter(card => {
                  const sort = sortings.find(sorting => sorting.lane == lane.id);
                  return sort && sort.order.includes(card.id)
                }).sort((a,b)=>{
                  const sort = sortings.find(sorting => sorting.lane == lane.id);
                  return sort && sort.order.indexOf(a.id) - sort.order.indexOf(b.id);
                })
              }));
            });
          });
        });
      }
    })
  }

  deleteCard(teamId: string) {
    this.lobService.deleteTeam(teamId, this.lobId);
  }

  createCard(lane: any) {
    this.cardService.createCard({
      name: lane.createCardName,
      lane: lane
    },this.teamId,this.lobId).then(card => {
      this.cardService.getSortings(this.teamId, this.lobId).pipe(take(1)).subscribe(sortings => {
        let sorting = sortings.find(x => x.lane == lane.id);
        if(sorting){
          sorting.order.push(card.id);
          this.cardService.updateSorting(sorting, this.teamId, this.lobId);
        } else {
          this.cardService.createSorting({
            lane: lane.id,
            order: [card.id]
          }, this.teamId, this.lobId);
        }
      })
      lane.createCardName = "";
      lane.createCard = false;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      this.cardService.getSortings(this.teamId, this.lobId).pipe(take(1)).subscribe(sortings => {
        let sorting = sortings.find(x => x.lane == event.container.id);
        if(sorting){
          moveItemInArray(sorting.order, event.previousIndex, event.currentIndex);
          this.cardService.updateSorting(sorting, this.teamId, this.lobId);
        }
        else {
          this.cardService.createSorting({
            lane: event.container.id,
            order: (event.container.data as Sorting[]).map(({id}) => id)
          }, this.teamId, this.lobId);
        }
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      });
    } else {
      this.cardService.getSortings(this.teamId, this.lobId).pipe(take(1)).subscribe(sortings => {
        let sortingFrom = sortings.find(x => x.lane == event.previousContainer.id);
        let sortingTo = sortings.find(x => x.lane == event.container.id);
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);

        if(sortingFrom){
          sortingFrom.order.splice(event.previousIndex, 1);
          this.cardService.updateSorting(sortingFrom, this.teamId, this.lobId);
        }
        if(sortingTo){
          sortingTo.order.splice(event.currentIndex, 0, (event.container.data[event.currentIndex] as any).id);
          this.cardService.updateSorting(sortingTo, this.teamId, this.lobId);
        } else {
          this.cardService.createSorting({
            lane: event.container.id,
            order: (event.container.data as Sorting[]).map(({id}) => id)
          }, this.teamId, this.lobId);
        }
      });
    }
  }

  startDrag(){
    this.titleService.setTouchAllowed(false);
  }

  endDrag(){
    this.titleService.setTouchAllowed(true);
  }

  onSubmit(value: Card) {
    this.cardService.updateCard({ id: this.activeCard.id, ...value }, this.teamId, this.lobId).then(() => this.form.markAsPristine());
  }

  setActive(value: Card){
    this.activeCard = value;
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.activeCard && this.activeCard.name ? this.activeCard.name : ''],
      problem: [this.activeCard && this.activeCard.problem ? this.activeCard.problem : ''],
      problem_metric: [this.activeCard && this.activeCard.problem_metric ? this.activeCard.problem_metric : ''],
      hypothesis: [this.activeCard && this.activeCard.hypothesis ? this.activeCard.hypothesis : ''],
      success_metric: [this.activeCard && this.activeCard.success_metric ? this.activeCard.success_metric : ''],
      outcome: [this.activeCard && this.activeCard.outcome ? this.activeCard.outcome : ''],
      notes: [this.activeCard && this.activeCard.notes ? this.activeCard.notes : ''],
      complexity: [this.activeCard && this.activeCard.complexity ? this.activeCard.complexity : ''],
      value: [this.activeCard && this.activeCard.value ? this.activeCard.value : ''],
      reporter: [this.activeCard && this.activeCard.reporter ? this.activeCard.reporter : ''],
      assignee: [this.activeCard && this.activeCard.assignee ? this.activeCard.assignee : ''],
    });
  }

  openDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  deleteActiveCard(){
    this.cardService.deleteCard(this.activeCard.id, this.teamId, this.lobId);
    this.activeCard = null;
  }
}