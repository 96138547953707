// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAdFq2hDXuskdhtnXMJZWQa-9oMWLzxCjM",
    authDomain: "devometer-40ede.firebaseapp.com",
    databaseURL: "https://devometer-40ede.firebaseio.com",
    projectId: "devometer-40ede",
    storageBucket: "devometer-40ede.appspot.com",
    messagingSenderId: "1095042879737",
    appId: "1:1095042879737:web:eb063afd7e90582ec65228",
    measurementId: "G-DF95RVMQPM"
  },
  authConfig: {
    enableFirestoreSync: true, // enable/disable autosync users with firestore
    toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
    toastMessageOnAuthError: true, // whether to open/show a snackbar message on auth error - default : true
    authGuardFallbackURL: '/authentication', // url for unauthenticated users - to use in combination with canActivate feature on a route
    authGuardLoggedInURL: '/home', // url for authenticated users - to use in combination with canActivate feature on a route
    passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
    passwordMinLength: 8, // Password length min/max in forms independently of each componenet min/max.
    // Same as password but for the name
    nameMaxLength: 50,
    nameMinLength: 2,
    // If set, sign-in/up form is not available until email has been verified.
    // Plus protected routes are still protected even though user is connected.
    guardProtectedRoutesUntilEmailIsVerified: true,
    enableEmailVerification: true, // default: true
  }
};
