import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Client } from '../model/client';
import { from } from 'rxjs';
import { mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private firestore: AngularFirestore,
    private auth: AngularFireAuth
  ) { }

  getClients() {
    return from(this.auth.currentUser).pipe(mergeMap(authState => {
      return this.firestore.collection('clients', ref => ref.where('owners', 'array-contains', authState.uid)).snapshotChanges();
    }));
  }

  getClient(id: string) {
    return this.firestore.doc('clients/' + id).snapshotChanges();
  }

  async createClient(client: Client) {
    const authState = await this.auth.currentUser;
    client.created = firebase.default.firestore.Timestamp.now().seconds;
    client.updated = firebase.default.firestore.Timestamp.now().seconds;
    client.createdBy = authState.uid;
    client.owners = [authState.uid];
    return this.firestore.collection('clients').add(client);
  }

  updateClient(client: Client) {
    client.updated = firebase.default.firestore.Timestamp.now().seconds;
    this.firestore.doc('clients/' + client.id).update(client);
  }

  deleteClient(id: string) {
    this.firestore.doc('clients/' + id).delete();
  }

  addOwner(email: string, id: string){
    return this.firestore.doc('clients/' + id).update({
      owners: firebase.default.firestore.FieldValue.arrayUnion(email)
    });
  }

  removeOwner(email: string, id: string){
    return this.firestore.doc('clients/' + id).update({
      owners: firebase.default.firestore.FieldValue.arrayRemove(email)
    });
  }
}
