import { HomeComponent } from './home/home.component';
import { ClientEditComponent } from './client/client-edit/client-edit.component';
import { LobEditComponent } from './lob/lob-edit/lob-edit.component';
import { ClientComponent } from './client/client.component';
import { BoardComponent } from './team/board/board.component';

export const rootRoutes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: 'client',
    component: ClientComponent,
    children: [
      { path: 'create', component: ClientEditComponent }
    ]
  },
  {
    path: 'client/:clientId',
    component: ClientComponent,
    children: [
      { path: 'edit', component: ClientEditComponent },
      { path: ':lobId/edit', component: LobEditComponent },
      { path: ':lobId/:teamId/dashboard', component: BoardComponent}
    ]
  }
]
