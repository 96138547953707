<!--<mat-toolbar color="primary">
  <span>Devometer</span>
  <span fxFlex></span>
  <ngx-auth-firebaseui-avatar></ngx-auth-firebaseui-avatar>
</mat-toolbar>
<p>Only for the authenticated!</p>
<button mat-stroked-button routerLink="/user">Profile</button>-->


<p>
   it works!
  </p>
