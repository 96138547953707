<div class="menu" fxFlex="100">
  <div class="clients mat-primary-background" [class.hidden]="clientSelectionHidden">
    <div class="client-selection">
      <ng-container *ngFor="let cl of clients">
        <button *ngIf="!cl.logo" mat-fab [class.active]="client && cl.id == client.id" [matTooltip]="cl.name" matTooltipPosition="right" [routerLink]="['/app/client', cl.id ,'edit']">
          {{ cl.name.substr(0,2) | uppercase }}
        </button>
        <ng-container *ngIf="cl.logo">
          <button *ngIf="'ClientLogo/thumbnails/'+cl.id+'_50x50' | fireStorageImage | async as photoUrl" [style.background-image]="'url(' + photoUrl + ')'" class="logoButton" [class.active]="client && cl.id == client.id" mat-fab
            [matTooltip]="cl.name" matTooltipPosition="right" [routerLink]="['/app/client', cl.id ,'edit']">
          </button>
        </ng-container>
      </ng-container>

      <button mat-fab matTooltip="Create Client" matTooltipPosition="right" routerLink="/app/client/create">
        +
      </button>
    </div>
    <button mat-fab  matTooltip="Hide Clients" matTooltipPosition="right" class="hide-clients" (click)="clientSelectionHidden = true">
      <
    </button>
  </div>
  <mat-nav-list class="list" [class.expanded]="clientSelectionHidden">
    <ng-container *ngIf="clients && client && id && lobs">
      <h3 mat-subheader>{{client.name}}</h3>
      <mat-nav-list fxLayout="column">
        <ng-container *ngFor="let lob of lobsForClient(id)">
          <a mat-list-item fxFlex="100" class="lob" [routerLink]="['/app/client/', id, lob.id, 'edit']">
            {{ lob.name }}
          </a>
          <mat-nav-list dense class="second-level">
            <a mat-list-item class="second-level-item" *ngFor="let team of lob.getTeams | async" [routerLink]="['/app/client/', id, lob.id, team.id, 'dashboard']">
              <mat-icon>keyboard_arrow_right</mat-icon> {{ team.name }}
            </a>
          </mat-nav-list>
        </ng-container>
      </mat-nav-list>
      <button mat-fab matTooltip="Show Clients" matTooltipPosition="right" class="show-clients" (click)="clientSelectionHidden = false" [class.shown]="clientSelectionHidden">
        >
      </button>
    </ng-container>
  </mat-nav-list>
</div>
