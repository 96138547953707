import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Lob } from '../model/lob';
import { Team } from '../model/team';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class LobService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  getLobs() {
    return this.firestore.collection('lobs', ref => ref.orderBy('created')).snapshotChanges();
  }

  getLob(id: string) {
    return this.firestore.doc('lobs/' + id).snapshotChanges();
  }

  getClientLobs(clientId: string) {
    const client = this.firestore.collection('clients').doc(clientId).ref;
    return this.firestore.collection('lobs', ref => ref.where('client','==',client)).snapshotChanges();
  }

  createLob(lob: Lob, clientId: string) {
    return this.firestore.collection('lobs').add({
      ...lob,
      client: this.firestore.collection('clients').doc(clientId).ref,
      created: firebase.default.firestore.FieldValue.serverTimestamp()
    });
  }

  updateLob(lob: Lob) {
    delete lob.id;
    this.firestore.doc('lobs/' + lob.id).update(lob);
  }

  deleteLob(lobId: string) {
    this.firestore.doc('lobs/' + lobId).delete();
  }

  getTeams(id: string){
    return this.firestore.collection('lobs/' + id + '/teams').snapshotChanges().pipe(
      map(changes => changes.map(({ payload: { doc } }) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...data as Team } as Team;
      }))
    );
  }

  createTeam(team: Team, lobId: string) {
    return this.firestore.collection('lobs/'+lobId+'/teams').add({
      ...team,
      created: firebase.default.firestore.FieldValue.serverTimestamp()
    });
  }

  updateTeam(team: Team, lobId: Lob) {
    delete team.id;
    this.firestore.doc('lobs/' + lobId + '/teams/' + team.id).update(team);
  }

  deleteTeam(teamId: string, lobId: string) {
    console.log("deleting team "+ teamId + " from lob "+ lobId);
    this.firestore.doc('lobs/' + lobId + '/teams/' + teamId).delete();
  }
}
